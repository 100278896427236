/*The gray background*/
.digi-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.7);
    /*padding: 50;*/
    z-index: 1000
}

/* The modal window */
.digi-modal {
    position: fixed;
    top: 47%;
    left: 47%;
}

@media print, screen and (min-width: 40em) {
    .digi-modal {
        position: fixed;
        top: 49%;
        left: 49%;
    }
}

.digi-spinner {
    color: #70cbf4;;
    font-size: 2em;
}

.digi-modal-close-button {
    position:fixed;
    top: 30%;
    left: 70%;
    background-color: #FFBABA;
    color: #D8000C;
    border: 1px solid;
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    z-index: 999    
}

.digi-modal-message-field {
    margin-left: 40%;
    margin-right: 40%;
    margin-Top: 20%;
    background-color: #FFBABA;
    color: #D8000C;
    border: 1px solid;
    padding: 15px 10px 15px 50px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    background-image: url('../images/error.png');
    background-repeat: no-repeat;
    background-position: 10px center
}

/* Planner Modal */
.modal-pin {
    margin-left: 25%;
    margin-top: 20%;
    margin-right: 25%;
    margin-bottom: auto;
}