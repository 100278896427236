
@-webkit-keyframes fadeInAnimation{
0%   {   left: -10em; }
8%   {   left: -9em; }
16%   {   left: -8em; }
33%   {   left: -7em; }
41%   {   left: -6em; }
50%   {   left: -5em; }
58%   {   left: -4em; }
64%   {   left: -3em; }
73%  {   left: -2em; }
81%  {   left: -1em; }
90%  {   left: 0; }
100% {   left: 1em; }
}

.infoContainer{
  transition: fadeInAnimation 0s, 1s linear;
  float: right;
  visibility: visible;
  border-radius: 2px;
  position: relative;
  display: inline-block;
  top: 0;
  left: -10em;
  z-index: 1000;
  width: 8.4em;
  height: 4.5em;
  background-color: #1e2c5c;
  color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin: 1em 1em 0.5em;
}

@media (min-width: 880px) {
  .infoContainer{
    display: block;
    left: 1em;
  }

  .hideOnLargeScreens{
    display: none;
  }
}

@media (min-height: 640px) {
  .leaflet-container {
    height: 67vh;
  }
}

.infoContainerBtnToggle{
  left: 1em;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: -15px;
}

/* Hide default HTML checkbox */
.switchCheckBox {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 0;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switchCheckBox:checked + .slider {
   background-color: #1e2c5c;
   height: 100%;
 }

.switchCheckBox + .slider {
  height: 100%;
}

.switchCheckBox:focus + .slider {
  box-shadow: 0 0 1px #1e2c5c;
  padding-bottom: 1em;
}

.switchCheckBox:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggleRow{
  padding-top: 0;
}

.showInfoContainerLeft{
  float: left;
  margin-right: 1em;
}

.showInfoContainerRight{
  float: right;
  position: relative;
  top: 0.85em;
}

.textRightLable{
  display: inline-block;
  width: 3em;
  color: #1e2c5c;
  position: relative;
  top: -2.5em;
  margin-left: 0.6em;
}

.textLeftLable{
  display: inline-block;
  width: 3.2em;
  color: #1e2c5c;
  position: relative;
  top: -2.5em;
}

@media (max-width: 400px) {
    .textRightLable{
    font-size: smaller;
    }

    .textLeftLable{
    font-size: smaller;
    }

}

#impContainer{
  display: none;
}

.leaflet-control-zoom-in{
    background-color: #1e2c5c !important;
    font-size: 1pt !important;
    color: #1e2c5c !important;
}

.leaflet-control-zoom-out{
    font-size: 1pt !important;
    background-color: #1e2c5c !important;
    color: #1e2c5c !important;
}

.leaflet-control-zoom-in:before {
    content: "\f00e";  /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: FontAwesome;
    left: 5px;
    position:absolute;
    top:0;
    font-weight: 100;
    font-size: 16pt;
    color: #FFF !important;
}

.leaflet-control-zoom-out:after{
    content: "\f010";  /* this is your text. You can also use UTF-8 character codes as I do here */
    font-family: FontAwesome;
    left: 5px;
    position:absolute;
    top: 1.4em;
    font-weight: 100;
    font-size: 16pt;
    color: #FFF !important;
}

.leaflet-control-zoom {
    border: none !important;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
}

.thumbnail {
  border: solid 1px lightgray;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
}

#snackbar {
  opacity: 0;
  min-width: 250px; 
  width: 50%;
  margin: auto; 
  background-color: #333;
  color: #fff;
  text-align: center; 
  border-radius: 2px; 
  padding-left: 10%;
  padding-right: 10%;
  z-index: 90;
  left: 50%;
}

#snackbar.show {
  opacity: 1;
  animation: fadein 1s, fadeout 1s;
}

@keyframes fadein {
  from { opacity: 0;}
  to { opacity: 1;}
}

@keyframes fadeout {
  from { opacity: 1;}
  to { opacity: 0;}
}

.video-js .theo-player-wrapper{
  background-color: transparent !important; 
}


.errorContainer{
    width: 50%;
    height: 50%;
    margin: 20% auto auto;
}


.custome-alert-danger {
    background-color: #eb4944;
    border-color: #de423d;
    color: white;
    min-width: 150px;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 3px;
    box-shadow: 0 1px 3px #000, 0 1px 2px #000;
}

.alert-header{
    font-size: 1.5em;
}


.errorContainer{
    width: 50%;
    height: 50%;
    margin: 20% auto auto;
}

.ImageButton_wrapper {
    text-align: center;
    margin: 1em;
}

.ImageButton_thumbnail {
    margin-bottom: 0 !important;
}

/* *********** */

table thead th {
    text-align: center;
}

.bold-header {
    font-weight: 600;
}

/*** LayoutSingleColumnImage ***/

.specialoffer-header {
    margin-bottom: 0.2em;
}

.specialofferimage {
    max-width: 50%;
}

.specialofferimagemargin {
    margin-right: 1rem;
}

@media (min-width: 500px) {

    .specialofferitem {
        display:flex;
        justify-content: space-between;
        margin-bottom: 1em;

    }

    .specialofferimage {
        max-width: 30%;
        flex-shrink: 0;
    }

    .specialofferimage img {
        margin-bottom: 0;
    }

}

/*** LayoutDoubleColumnImageDiffColors ***/

.specialmessageimage {
    max-width: 50%;
}


.specialmessageimagemargin {
    margin-right: 1rem;
}

@media (min-width: 500px) {

    .specialmessageitem {
        display:flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;

    }

    .specialmessageimage {
        max-width: 30%;
        flex-shrink: 0;
    }

    .specialmessageimage img {
        margin-bottom: 0;
    }

}

@media (min-width: 800px) {

    .specialmessagesflexcontainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .specialmessageflexitem {
        flex: 0 0 49%;
    }

}

.whale_NoResult {
    font-size: 30pt;
}

/* *********** */

.weather-heading{
  font-size: 1.5rem;
  font-weight: bold;
}

.weather-container {
    display: flex;
    min-height: 8em;
    max-height: 10rem;
    width: 100%;
    margin-bottom: 1em;
}

@media (min-width: 800px) {
    .weather-container {
        width: 70%;
    }
    .weather-heading{
      font-size: 2rem;
    }
}

.weather-switch-container div {
    background-color: #72cbf4;
}

.weather-switch-container div:hover {
    background-color: revert;
    color: revert;
}

.weather-switch-container div:first-child{
    border-radius: 4px 0 0 4px;
}

.weather-switch-container div:last-child{
    border-radius: 0 4px 4px 0;
}

.day-switch-button-active {
    background-color: #B2E7F8!important;
    font-weight: bolder;
    text-decoration: underline;
}

.weather-icon {
    position: relative;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 98%;
    width: 95%;
    padding: 0.5rem;
}

.weather-schedule-field {
    line-height: 1.5rem;
}

.weather-schedule-heading {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.25rem;
}

.weather-icon-container {
    background: white;
    width: 35%;
}

body{
    font-size: 16px;
}

.cell .no-padding-cell{
    padding-left: 0;
    padding-right: 0;
}

tr .text-center{
    text-align: center !important;
}

th{
    text-align: inherit !important;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}
/* *********** */